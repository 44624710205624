$(function() {
	$('.video').click(function(e) {
		e.preventDefault();
		$(this).addClass('active');
	});

	$('.load_more_posts').click(function(e){
		e.preventDefault();
		var $$ = $(this);
		var next_page = $(this).data('next');
		var page_id = $(this).data('page_id');
		var parent = $(this).data('parent');
		var limit = $(this).data('limit');
		var category = $(this).data('category');

		if(typeof(page_id) == 'undefined' || page_id == '') page_id =2;

		$.ajax(next_page + '?page=' + page_id + '&parent=' + parent + '&limit=' + limit + '&category=' + category)
		.done(function(data) {
			$$.data('page_id',page_id+1);
			var posts = $(data.items).find('.post_wrap');

			$('.posts-background').append(posts);
			if(!data.more_pages) {
				$$.hide();
			}
		});
	});

	initImage();
	$(".banner").queue(function(next){
		next();
	});
	$(".banner-menu").delay(2000).queue(function(next){
		$(this).addClass('actives');
		$(".mobile_menu").addClass('banner-active');
		$(".spacer").addClass('active-sp');

		next();
	});

	$("a[href*='#contact-us']").click(function(e){
		e.preventDefault();
		var form = $('#contact_form').parents('.container');
		scrollTo(form);
	});

});

function initImage() {
	$('.product_product_image>div').click(function(e) {
		e.preventDefault();

		var src = $(this).find('img').attr('src');
		var src_set = $(this).find('img').attr('src-set');

		$('.image_holder img').attr('src',src);
		$('.image_holder img').attr('src-set',src_set);

		var img_parent = $('.image_holder').find('img').parent();
		img_parent.attr('href',src);
		img_parent.data('lightbox',$('.product_product_image').index($(this)));

		$('.product_product_image>div.active').removeClass('active');
		$(this).addClass('active');

		return false;
	});
};

lightbox.option({
	'albumLabel': 'Image %1'
})
