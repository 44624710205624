
;
$(function() {
	initImageReel();
});

function initImageReel() {
	$('.image_reel').each(function(i,itm) {
		calcReel($(this));
	});

	$(window).resize(function() {
		$('.image_reel').each(function(i,itm) {
			calcReel($(this));
		});
	});
};

function calcReel($el) {
	var div_width = $el.find('.breakout').width() ;

	var animation_id = $el.data('animation_id');
	if(!animation_id) {
		animation_id =  'reel_'+cuniq();
	}
	$el.data('animation_id',animation_id);

	var $img = $('.breakout img',$el);
	var duration = $img.data('duration');

	var img_width = $img.width() ;
	var max_move = img_width-div_width;


	$.keyframe.define([{
		name: animation_id,
		'100%': {transform:"translateX(-"+max_move+"px)"}
	}]);

	$img.playKeyframe({
		name: animation_id,
		duration: duration+'s',
		timingFunction: 'ease-in-out',
		delay: '0s',
		iterationCount: 'infinite',
		direction: 'alternate',
		fillMode: 'forwards',
	});

};

var c = 1;
function cuniq() {
	var d = new Date(),
	m = d.getMilliseconds() + "",
	u = ++d + m + (++c === 10000 ? (c = 1) : c);
	return u;
};
