;
$(function() {
	$('.dropdown').hide();
	$('[data-toggle="popover"]').popover();
	mobilePageRowCollapse();

	$('.dropdown-toggle').click(function(e) {
		e.preventDefault();

		var $parent = $(this).parent();
		if($('.dropdown',$parent).is(':visible')) {
			$parent.removeClass('active');

			$('.dropdown',$parent).hide();
		}
		else {
			$parent.addClass('active');

			$('.dropdown',$parent).show();

			setTimeout(function() {
				$( document ).one( "click", function() {
					$('.dropdown-toggle',$parent).click();
				});
			},1);
		}
	});

	$('.toggle_nav').click(function(e) {

		e.preventDefault();

		$('.mobile_menu').toggleClass('active');
		$('body').toggleClass('no_scroll');
		$(this).toggleClass('active').blur();
	});
});

function mobilePageRowCollapse() {
	$('.show-mobile-row').on('click', function(e) {
		e.preventDefault();
		var mobile_row = $(this).parent('div').parent('div').find('.mobile-row');
		mobile_row.toggleClass('open', 1000, 'swing');
		if (mobile_row.hasClass('open')){
			$(this).text('Read More');
		} else {
			$(this).text('Show Less');
		}
	});
};
