;
$(function() {
	initJumpNav();
});

function initJumpNav() {
	var jump_links = $('.jump_nav_link');

	jump_links.each(function(itm) {
		var direction = $(this).data('direction');

		if(direction == 'down') {
			if(jump_links.index(this) < jump_links.length - 1) {
				$(this).show();
			}
		} else if(direction == 'up') {
			if(jump_links.index(this) > 0) {
				$(this).show();
			}
		}
	});

	$(document).on('touchstart click','.jump_nav_link',function(e) {
		e.preventDefault();
		var direction = $(this).data('direction');
		var idx = jump_links.index(this);

		if(direction == 'down') {
			scrollTo(jump_links.eq(idx+1).parents('.jump_nav_group'));
		} else if(direction == 'up') {

			if((idx-1) == 1) {
				scrollTo(null);
			}
			else {
				scrollTo(jump_links.eq(idx-1).parents('.jump_nav_group'));
			}
		}
	});
};

function scrollTo($el, extra) {
	if(extra == null) {
		extra = 0;
	}
	if($el == null) {
		$('html, body').animate(
		{
			scrollTop: 0
		}, 750);
		return;
	}
	if($.isFunction($el.offset)) {
		var spacer = $('.spacer').outerHeight();

		$('html, body').animate({
			scrollTop: $el.offset().top - spacer - extra
		}, 350);
	}
};
