var Product = function(data) {
	this.product_id = data.product_id;
	this.price =  new Number(data.price);
	this.price2 =  new Number(data.price2);
	this.standard_price = data.price;
	this.price3 =  new Number(data.price3);
	this.price4 =  new Number(data.price4);
	this.volume1 =  new Number(data.volume1);
	this.volume2 =  new Number(data.volume2);
	this.volume3 =  new Number(data.volume3);
	this.product_code = data.product_code;
	this.product_name = data.product_name;
	this.product_description = data.product_description;
	this.product_contains = data.product_contains;
	this.product_image = data.product_image;
	this.product_image_retina = data.product_image_retina;
	this.product_label = data.product_label;
	this.vat = new Number(1.2);
};

Product.prototype.getStandardPrice = function() {
	return this.price.toFixed(2);
}

Product.prototype.getPrice = function() {

	var quantity = new Number($('#quantity').val());
	var sub_total = (this.price * quantity);
	if (this.volume1 == 0) {
		sub_total = (this.price * quantity);

	} else {
		if (quantity <= this.volume1){
			sub_total = (this.price * quantity);
		}
		else if (quantity <= this.volume2) {
			sub_total = (this.price2 * quantity);
		}
		else if (quantity <= this.volume3) {
			sub_total = (this.price3 * quantity);
		}
		else {
			sub_total = (this.price4 * quantity);

		}
	}


	return sub_total;
}

Product.prototype.getItemPrice = function(quantity) {

	var sub_total = this.price;

	if (this.volume1 == 0) {
		sub_total = this.price;

	} else {
		if (quantity <= this.volume1){

			sub_total = this.price;
		}
		else if (quantity <= this.volume2) {
			sub_total = this.price2;
		}
		else if (quantity <= this.volume3) {
			sub_total = this.price3;
		}
		else {
			sub_total = this.price4;

		}
	}

	return sub_total;
}


Product.prototype.getPrice2 = function() {
	return this.price2;
}

Product.prototype.getPrice3 = function() {
	return this.price3;
}

Product.prototype.getPrice4 = function() {
	return this.price4;
}

Product.prototype.getPriceWithVat = function() {
	var pvat = this.price * this.vat;
	return pvat;
}

Product.prototype.getSubTotalPrice = function(quantity) {
	var sub_total = this.price;
	if (this.volume1 == 0) {
		sub_total = (this.price * quantity);

	} else {
		if (quantity <= this.volume1){
			sub_total = (this.price * quantity);
		}
		else if (quantity <= this.volume2) {
			sub_total = (this.price2 * quantity);
		}
		else if (quantity <= this.volume3) {
			sub_total = (this.price3 * quantity);
		}
		else {
			sub_total = (this.price4 * quantity);

		}
	}
	return new Number(sub_total);
}



Product.prototype.getSubTotalVAT = function(quantity) {
	var sub_total = this.price;

	if (this.volume1 == 0) {
		sub_total = (this.price * quantity);

	} else {
		if (quantity <= this.volume1){
			sub_total = (this.price * quantity);
		}
		else if (quantity <= this.volume2) {
			sub_total = (this.price2 * quantity);
		}
		else if (quantity <= this.volume3) {
			sub_total = (this.price3 * quantity);
		}
		else {
			sub_total = (this.price4 * quantity);

		}
	}

	var vat = sub_total * (this.vat - 1);
	return new Number(vat);
}




Product.prototype.getProductCode = function() {
	return this.product_code;
}

Product.prototype.getProductId = function() {
	return this.product_id;
}

Product.prototype.getVolume = function() {
	return this.volume;
}


Product.prototype.getUID = function() {
	return this.product_id;
}

Product.prototype.getProductName = function() {
	return this.product_name;
}

Product.prototype.getProductDescription = function() {
	return this.product_description;
}

Product.prototype.getProductImage = function() {
	return this.product_image;
}

Product.prototype.getProductImageRetina = function() {
	return this.product_image_retina;
}

Product.prototype.getRelatedParams = function() {
	return {
		'product_id': this.product_id,
	};
}

Product.prototype.getProductLabel = function() {
	return this.product_label;
}

Product.prototype.getProductContains = function() {
	return this.product_contains;
}


$(function() {
	$.ajaxSetup({
		data: {'_token' : $('meta[name="_token"]').attr('content')},
	});

	initCart();

	initProducts();
	initImage();


	if($('.contact textarea[name="message"]').length > 0) {
		var data = localStorage.getItem('enquiry');
		if(typeof with_cart != 'undefined' && with_cart) {
			$('.contact textarea[name="message"]').val(data);
		}
	}
});

function initCart() {
	Cart.init({
		checkout_url:'/checkout',
	});

}



function initProducts() {
	$.ajax({
		url: $('.product_options').data('info-url'),
	}).done(function(data) {

		var product = new Product(data);
		Cart.setCurrentProduct(product);
	});


	if($('.product_options').length > 0) {
		$('.product_options').on('change input keyup','input,select',function() {
			updateProduct();
		});
	}

	function updateProduct() {
		if(Cart.currentProduct) {
			Cart.setCurrentProduct(Cart.currentProduct);
		}
		var quantity = new Number($('#quantity').val());
		if(Cart.currentProduct.volume1 != 0) {

			if (quantity <= Cart.currentProduct.volume1){
				$('.table_selector').removeClass('volume2 volume3 volume4');
			}
			else if (quantity <= Cart.currentProduct.volume2) {
				$('.table_selector').addClass('volume2');
				$('.table_selector').removeClass('volume3 volume4');
			}
			else if (quantity <= Cart.currentProduct.volume3) {
				$('.table_selector').addClass('volume3');
				$('.table_selector').removeClass('volume2 volume4');

			}
			else {
				$('.table_selector').addClass('volume4');
				$('.table_selector').removeClass('volume2 volume3');

			}
		}
	}
}

function initImage() {
	$('.product_product_image>div').click(function(e) {
		e.preventDefault();

		var src = $(this).find('img').attr('src');
		var src_set = $(this).find('img').attr('src-set');

		$('.image_holder img').attr('src',src);
		$('.image_holder img').attr('src-set',src_set);

		var img_parent = $('.image_holder').find('img').parent();
		img_parent.attr('href',src);
		img_parent.data('lightbox',$('.product_product_image').index($(this)));

		$('.product_product_image>div.active').removeClass('active');
		$(this).addClass('active');

		return false;
	});
}

function initCartEnquire() {
	$('[name="delivery"]').change(function() {
		if($(this).is(':checked') && $(this).val() == 'overseas') {
			$('.hide_on_enquire').hide();
			$('.show_on_enquire').show();

			//generate an enquiry message into local storage
			var enquireText = "I'm interested in purchasing: ";
			Cart.basket.each(function(i,itm) {
				enquireText += "\n"+itm.quantity+" x "+itm.item.getProductName() + " ["+itm.item.getProductCode()+"] (£"+itm.item.getPriceWithVat()+")";
			});
			localStorage.setItem('enquiry',enquireText);
		}
		else {
			$('.hide_on_enquire').show();
			$('.show_on_enquire').hide();
		}
	});
}

function initHashNav() {
	var hash = window.location.hash;
	if(hash && hash != '') {
		$("html, body").animate({ scrollTop: $(hash).offset().top }, 1000);
	}
}


$(function() {
	$('#mobile_nav_button').click(function(e) {
		e.preventDefault();
		$('#mobile_nav').toggleClass('active');
		$('body').toggleClass('menu-active');
	});
})

