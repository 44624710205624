;
// MapMarker Icon
var icon = '/media/images/mapmarker@2x.png';

var map;
function initialize() {
	if($('#map').length == 0) return;

	map = new google.maps.Map(document.getElementById('map'), {
		zoom: 16,
		center: new google.maps.LatLng(latitude,longitude),
		mapTypeId: 'roadmap',
		animation: google.maps.Animation.DROP,
	});

	addMarker({
		position: new google.maps.LatLng(latitude,longitude),
		type: 'info',
		animation: google.maps.Animation.DROP,
	});
};

function addMarker(feature) {
	var marker = new google.maps.Marker({
		position: feature.position,
		animation: google.maps.Animation.DROP,
		icon: new google.maps.MarkerImage(icon, null, null, null, new google.maps.Size(30,49)),
		map: map
	});
};
