var DefaultAdapter = {
	list_template: [],
	init: function() {
		//if there is a template grab it and remove it.
		if($('.cart-list-tpl').length > 0) {
			this.list_template = $('.cart-list-tpl').clone().removeClass('cart-list-tpl');
			$('.cart-list-tpl').remove();

			$('.cart-basket-list').on('change','input',this.onBasketListUpdate);
		}
	},
	onBasketListUpdate: function(e) {
		var row = $(this).parents('tr');
		var new_quantity = $(this).val();
		var id = row.attr('id');

		Cart.basket.updateItem(id,new_quantity);

		Cart._persistData();
		DefaultAdapter.updateCart(Cart.basket);
		DefaultAdapter.renderBasketList(Cart.basket);
	},
	renderBasketList: function(basket) {
		if($('.cart-basket-list').length > 0) {
			var e = 1;
			$('.cart-basket-list .cart_ns_list').remove();
			basket.each(function(i,itm) {
				var row = DefaultAdapter.list_template.clone();
				DefaultAdapter._updateCartValues('.cart_ns_list',itm.item,row,function(field) {

					if(field == 'idx') {
						return e;
					}
					else if(field == 'quantity') {
						return itm.quantity;
					}
					else if(field == 'prices') {

						return itm.item.getItemPrice(itm.quantity);
					}

					else if(field == 'sub_total') {
						return itm.item.getSubTotalPrice(itm.quantity);
					}
					else if(field == 'delivery') {
						var total = itm.item.getSubTotalPrice(itm.quantity);
						return itm.item.getFreeDelivery(total);
					}
				});

				$('.cart-basket-list tbody>tr.bottom').before(row);
				e++;
			});

			DefaultAdapter._updateCartValues('.cart_ns_basket',basket);
		}
	},
	renderCurrent: function(product) {
		this._updateCartValues('.cart_ns_current',product);
	},
	initCart: function(checkout_url) {
		var tmpl = '<span class="cart_value cart_ns_basket" data-field="item_count">0</span>&nbsp;<i class="fa fa-shopping-cart" /> Checkout';

		var icon_a = $('<a />').attr('href',checkout_url).html(tmpl);
		var icon_li = $('<li />').addClass('cart_button').hide().append(icon_a);
		$('.nav').append(icon_li);
		// this.initDelivery();

		DeliveryAdapter.init();


	},

	updateCart: function(basket) {
		if(basket.getItemCount) {
			if(basket.getItemCount() > 0)
				$('.cart_button').show();
			else
				$('.cart_button').hide();
		}
		else {
			console.error('Basket does not implement getItemCount');
		}

		this._updateCartValues('.cart_ns_basket',basket);
		DeliveryAdapter.updateDelivery();
	},
	showDialog: function(cur_product,quantity) {
		//assume there is a dialog in html with id of #product_added

		//fill any values specific to this product.
		this._updateCartValues('.cart_ns_pam',cur_product);
		this._updateCartValues('.cart_ns_q',{
			getQuantity:function() {
				return quantity;
			}
		});


		$('#product_added').modal('show');
	},
	emptyCart: function() {
		Cart.basket.clearBasket();
		Cart._persistData();
		DefaultAdapter.updateCart(Cart.basket);
		DefaultAdapter.renderBasketList(Cart.basket);
	},
	_updateCartValues: function(filter, context, el,unknowns) {
		if(el && typeof el.data('id') !== 'undefined') {
			get_field = DefaultAdapter._snakeToCamel('get_'+el.data('id'));
			if(typeof context[get_field] == 'function') {
				var value = context[get_field]();

				value = DefaultAdapter._formatValue(el,value);
				el.attr('id',value);
			}
			else {
				console.warn(get_field+' not found in context');
			}
		}

		$('.cart_value'+filter, el ? el : document).each(function() {
			var field = $(this).data('field');
			get_field = DefaultAdapter._snakeToCamel('get_'+field);
			if(typeof context[get_field] == 'function') {
				var value = context[get_field]();


				value = DefaultAdapter._formatValue($(this),value);
				DefaultAdapter._setElToValue($(this),value);
			}
			else {
				if(unknowns) {
					var value = unknowns(field);
					if(!value) {
						console.warn(get_field+' not found in context');
					}
					value = DefaultAdapter._formatValue($(this),value);
					DefaultAdapter._setElToValue($(this),value);
				}
			}
		});

		$('.cart_load'+filter, el ? el : document).each(function(i,itm) {

			var url = $(this).data('url');

			var params = {};
			if(typeof context['getRelatedParams'] == 'function')  {
				params = context.getRelatedParams();
			}

			$(this).load(url,params);
		});
	},
	_formatValue: function(el,value) {
		if(typeof el.data('prepend') !== 'undefined') {
			return el.data('prepend')+value.toFixed(2);
		}
		return value;
	},
	_setElToValue: function(el,value) {
		if(el.is('img')) {
			el.attr('src',value);
		}
		else if(el.is('input')) {
			el.val(value);
		}
		else {
			el.html(value);
		}
	},
	_snakeToCamel: function(s){
		return s.replace(/(_\w)/g, function(m){return m[1].toUpperCase();});
	},
}

var Basket = function() {
	this.items = {};
	this.vat = new Number(1.2);
}

Basket.prototype.addItem = function(item,quantity) {
	//get a unique identity for this item / options combination.
	var uuid;
	if(item.getUID) {
		uuid = item.getUID();
	}
	else {
		console.warn('item does not implement getUID, cart items will not be grouped');
		uuid = 'AUTO'+Math.round(Math.random() * 10000);
	}

	if(this.items[uuid]) {
		this.items[uuid].quantity += parseInt(quantity);
	}
	else {
		this.items[uuid] = {'item':item, 'quantity' : parseInt(quantity)};
	}
}

Basket.prototype.removeItem = function(uid) {
	if(this.items[uid]) {
		delete this.items[uid];
	}
}

Basket.prototype.updateItem = function(uid,quantity) {
	if(this.items[uid]) {
		this.items[uid].quantity = quantity;
	}
}

Basket.prototype.getItemCount = function(item) {
	var total = 0;
	$.each(this.items,function(i, itm) {
		total += Number(itm.quantity);
	});

	return total;
}

Basket.prototype.clearBasket = function(item) {
	this.items = {};
}

Basket.prototype.getTotalExVat = function(item) {
	var total = 0;
	$.each(this.items,function(i, itm) {
		total += itm.item.getSubTotalPrice(itm.quantity);
	});
	var cost = $('#del-cost').text().split("£").join("");
	total += new Number(cost);
	return total;
}

Basket.prototype.getTotalVat = function(item) {
	var total = 0;
	$.each(this.items,function(i, itm) {
		total += itm.item.getSubTotalVAT(itm.quantity);
	});
	var cost = $('#del-cost').text().split("£").join("");
	var delivery = new Number(cost)* (this.vat - 1);
	total += delivery;
	return total;
}

Basket.prototype.getTotalIncVat = function(item) {
	return this.getTotalExVat() + this.getTotalVat();
}

Basket.prototype.each = function(cb) {
	return $.each(this.items,cb);
}

/* Cart */
var Cart = {
	opts: null,
	adapter: DefaultAdapter,
	basket: new Basket(),
	init: function(options) {
		if (typeof(Storage) === "undefined") {
			console.error('Storage is required for cart to work');
		}

		var defaults = {
			checkout_url: '#',
		}

		this.opts = $.extend({}, defaults, options);

		this.adapter.initCart(this.opts.checkout_url);

		this._loadData();
		this.adapter.init();
		this.adapter.updateCart(this.basket);
		this.adapter.renderBasketList(this.basket);

		this._bind();
	},
	_bind: function() {
		$(document).on('click','.cart_action',function(e) {
			var action = $(this).data('action');
			if(action == 'add_to_basket') {
				var quantity = Number($('.cart_quantity').val());
				Cart._addToBasket($(this),quantity);
			}
			else if(action == 'remove') {
				var id = $(this).parents('tr').attr('id');
				Cart._removeItem(id);
			}
		});
	},
	_addToBasket: function(el, quantity) {
		this.basket.addItem(this.currentProduct,quantity);

		//animate
		this._persistData();

		this.adapter.showDialog(this.currentProduct,quantity);

		this.adapter.updateCart(this.basket);
	},
	_removeItem: function(id) {
		this.basket.removeItem(id);

		//animate
		this._persistData();

		this.adapter.updateCart(this.basket);
		this.adapter.renderBasketList(this.basket);
	},
	setCurrentProduct: function(product) {
		this.currentProduct = product;
		this.adapter.renderCurrent(product);
	},
	_loadData: function() {
		var data = JSON.parse(localStorage.getItem('basket',data));

		if(data) {
			$.each(data.items,function(i,itm) {
				var product = Cart.product_factory(itm.item);
				Cart.basket.addItem(product,itm.quantity);
			});
		}
	},
	_persistData: function() {
		var data = JSON.stringify(this.basket);
		localStorage.setItem('basket',data);
	},
	product_factory: function(data) {
		if(Product) {
			return new Product(data);
		}
		else {
			console.error('Product Object does not exist, create one or override Cart.basket.product_factory');
		}
	},
}
