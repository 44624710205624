var nationalHolidays = [
[14, 4, 2017], [17, 4, 2017], [1, 5 , 2017], [29, 5, 2017], [28, 8, 2017], [25, 12, 2017], [26, 12, 2017], [1, 1, 2018], [30, 3, 2018], [2, 4, 2018],
[7, 5, 2018], [28, 5, 2018], [27, 8, 2018], [25, 12, 2018], [26, 12, 2018], [1, 1, 2019], [25, 12, 2019], [26, 12, 2019], [1, 1, 2020], [25, 12, 2020],
[26, 12, 2020], [1, 1, 2021],
];

var DeliveryAdapter = {
	init: function() {
		this.initDelivery();
	},
	CheckTime: function() {
		var today = new Date().getHours();
		var saturday =  new Date().getDay();
		$('.del_value'+'.del_ns',  document).each(function() {
			if (today >= 15) {
				$(".del_nextday").prop("disabled", true);
				if (saturday == 5) {
					$(".del_saturday").prop("disabled", true);
				}

			} else {
				$(".del_nextday").prop("disabled", false);
				if (saturday != 5) {
					$(".del_saturday").prop("disabled", false);
				}
			}
		});
	},
	AddBusinessDays: function(weekDaysToAdd) {
		var curdate = new Date();
		var realDaysToAdd = 0;
		while (weekDaysToAdd > 0){
			curdate.setDate(curdate.getDate()+1);
			realDaysToAdd++;
			if (curdate.getDay() != 0 && curdate.getDay() != 6) {
				var bh = this.bankHolidays(curdate);

				if(bh == false) {

					weekDaysToAdd--;
				}
			}
		}
		return realDaysToAdd;

	},
	bankHolidays: function(date) {

		for (i = 0; i < nationalHolidays.length; i++) {
			if (date.getMonth() == nationalHolidays[i][1] - 1 && date.getDate() == nationalHolidays[i][0] && date.getFullYear() == nationalHolidays[i][2]) {
				console.log('Bank Holiday on ' + nationalHolidays[i]);
				return true;
			}
		}
		return false;
	},
	updateDelivery: function() {
		var total = Cart.basket.getTotalIncVat();
		this.CheckTime();
		$('.del_value'+'.del_ns',  document).each(function() {
			var free_delivery = $(this).data('free');
			if (free_delivery) {
				if (new Number(total) >= new Number(free_delivery)) {
					$(".del_standard").prop("checked", false);
					$(".del_parcel").prop("checked", false);
					if(!$('.del-radio').is(':checked')) {
						$(".del_free").prop("checked", true);
						$('.del-cost').text('£' + $(".del_free").data('cost'));
					}




					$(".del_free").prop("disabled", false);
					$(".del_standard").prop("disabled", true);
					$(".del_parcel").prop("disabled", true);

				} else {
					$(".del_free").prop("checked", false);
					if(!$('.del-radio').is(':checked')) {

						$(".del_standard").prop("checked", true);
						$('.del-cost').text('£' + $(".del_standard").data('cost'));
						$('.expected').val($(this).data('expected'));
					}



					$(".del_free").prop("disabled", true);
					$(".del_standard").prop("disabled", false);
					$(".del_parcel").prop("disabled", false);
				}
			}

		});

	},
	formatDate:function(date_to_format) {
		var dd = date_to_format.getDate();
		var mm = date_to_format.getMonth() + 1;
		var y = date_to_format.getFullYear();

		var date_to_format_human = dd.toFixed(0) + '/'+ mm.toFixed(0) + '/'+ y.toFixed(0);

		return date_to_format_human;
	},
	updateDate: function(DaysToAdd) {
		var adddays = this.AddBusinessDays(DaysToAdd);
		var delivery_date = new Date();
		delivery_date.setDate(delivery_date.getDate() + adddays);

		return this.formatDate(delivery_date);

	},
	saturday: function() {
		var is_it_saturday = new Date();
		is_it_saturday.setDate(is_it_saturday.getDate()+1);

		while (is_it_saturday.getDay() != 6){
			is_it_saturday.setDate(is_it_saturday.getDate()+1);

		}
		return  this.formatDate(is_it_saturday);

	},
	initDelivery: function() {
		this.getDeliveryValues('.del_ns');

	},
	nextSaturday: function (date) {
		var ret = new Date(date||new Date());
		ret.setDate(ret.getDate() + (6 - 1 - ret.getDay() + 7) % 7 + 1);
		return this.formatDate(ret);
	},
	getDeliveryValues: function(filter) {

		$('.del_value'+filter, document).each(function() {
			var del_id = $(this).attr('id');
			var days = $(this).data('days');
			var cost = $(this).data('cost');
			var date = DeliveryAdapter.updateDate(new Number(days));
			if (del_id == 'del_saturday') {
				date = DeliveryAdapter.nextSaturday();

			}



			$(this).data('expected',date);


			$('.' + del_id).text('(Delivered ' +date +')');
		});
	},

};


$('#payment_form input[type=radio]').change(function() {
	$('.del-cost').text('£' + $(this).data('cost'));
	$('.expected').val($(this).data('expected'));
});

