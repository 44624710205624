var tracking = {
	init: function() {
		this.setWatcher('phone-click', 'clicked' , 'phone');
		this.setWatcher('email-click', 'pressed' , 'email');
	},
	setWatcher: function(watch_class, event_action, event_label) {
		$('.' +  watch_class).on('click', function (e) {
			ga('send', {
				hitType: 'event',
				eventCategory: 'Contact',
				eventAction: event_action,
				eventLabel: event_label
			});
		});
	},
};
$(function () {
	tracking.init();
});
